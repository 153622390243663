<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="968"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-tabs
          show-arrows
          :class="['b-tab-horizontal', hideTab ? 'd-none' : '']"
          v-model="tab"
        >
          <v-tab
            v-for="(header, index) in tabs"
            :href="`#${header.href}`"
            :key="index"
          >
            <v-icon small left>{{ header.icon }}</v-icon>
            {{ header.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(content, index) in tabs"
            :value="content.href"
            :key="index"
            :class="[hideTab ? 'pt-4' : 'pt-6']"
          >
            <component
              v-if="content.href === tab"
              :is="content.component"
              :userId="userId"
              :edit="isEdit"
              @closeModal="closeModal"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { PartnerService } from '@/services/api/partners';

export default {
  mixins: [rulesMixin],

  components: {
    PageUser: () => import('@/components/pages/user/page-user'),
    PagePartner: () => import('@/components/pages/user/page-partner')
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false
    },
    userId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    tab: null,
    hideTab: true,
    tabs: [
      {
        href: 'tab-1',
        title: 'Dados cadastrais',
        icon: 'fa-regular fa-file-lines',
        component: 'PageUser'
      },
      {
        href: 'tab-2',
        title: 'Parceiros',
        icon: 'fa-solid fa-handshake',
        component: 'PagePartner'
      }
    ]
  }),

  created() {
    if (this.isEdit) {
      this.search();
    }
  },

  methods: {
    async search() {
      try {
        const partnerService = new PartnerService();
        const { status, data } = await partnerService.getAvailablePartners(
          this.userId
        );

        if (status === 200) {
          this.hideTab = data.length === 0 ? true : false;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeModal(search) {
      this.$emit('closeModal', search);
    }
  }
};
</script>
